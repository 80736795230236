import React, {useState, useEffect, useContext} from 'react'
import { Link, NavLink } from 'react-router-dom'
// import { AuthContext } from '../auth/Auth'
import "../../index.css"

import { Carousel } from 'react-responsive-carousel';
// import React, {useState, useEffect} from 'react';

const images = [
    'assets/rc_images/landscapeSlider1.png',
    'assets/rc_images/landscapeSlider2.png'
];

const Header = () => {
  
    // const { currentUser } = useContext(AuthContext);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
        }, 7000); // เปลี่ยนเวลาในหน่วยมิลลิวินาที
    
        return () => clearInterval(interval);
      }, []);

    return (
        <>
          <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">    
                  <div className="container">
                      {/* <a className="navbar-brand cta hvr-float-shadow" href="#home">WANPROSOFT</a> */}
                      <span className="navbar-brand cta hvr-float-shadow"> <b><span >WANPROSOFT</span> </b> </span>
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      {/* <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"> */}
                              {/* Menu  */}
                           {/* <i className="fas fa-bars"></i> */}
                          {/* <span className="navbar-toggler-icon"></span>     */}
                      {/* </button> */}
                      <div className="navbar-collapse collapse" id="navbarResponsive">
                          <ul className="navbar-nav active ml-auto">
                              <li className="nav-item active">
                                  <NavLink exact to="/" activeClassName="is-active" className="nav-link hvr-underline-from-center">Home</NavLink>
                              </li>
  
                              <li className="nav-item active">
                                  <NavLink exact to="/Prices" activeClassName="is-active" className="nav-link hvr-underline-from-center">Prices</NavLink>
                              </li>
                              <li className="nav-item active">
                                  <NavLink exact to="/Download" activeClassName="is-active" className="nav-link hvr-underline-from-center">Cloud-SMS-Download</NavLink>
                              </li>
  
                              <li className="nav-item active">
                                  <div class="dropdown">
                                      <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <span style={{color:'#FFFFFF'}}>Programs</span>
                                      </button>
                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"> 
                                         <a class="dropdown-item hvr-underline-from-center" href><NavLink exact to="/"><span style={{color:'#000000'}}>โปรแกรมคลินิกทันตกรรม.SDent</span></NavLink></a>
                                         <a class="dropdown-item hvr-underline-from-center" href><NavLink exact to="/SBeauty"><span style={{color:'#000000'}}>โปรแกรมคลินิกความงาม.SBeauty</span></NavLink></a>
                                         <a class="dropdown-item hvr-underline-from-center" href><NavLink exact to="/Spos" ><span style={{color:'#000000'}}>โปรแกรมร้านมินิมาร์ท.SPos</span></NavLink></a>
                                         <a class="dropdown-item hvr-underline-from-center" href><NavLink exact to="/SCRM" ><span style={{color:'#000000'}}>โปรแกรมบริหารงานกำจัดปลวก.SCRM</span></NavLink></a>
                                      </div>
                                  </div>
                              </li>
  
                              <li className="nav-item active">
                                  <NavLink exact to="/Contact" activeClassName="is-active" className="nav-link hvr-underline-from-center">Contact</NavLink> 
                              </li>
                          </ul>
                      </div>
  
                  </div>
                </nav>
  
                {/* <!-- Page Content  --> */}
                {/* <div className="container-fluid" style={{margin:"57px"}}></div>     */}
                <div className="container-fluid" style={{margin:"54px"}}></div>   
                
            
          </div>

          <div className="container-fluid"> 
                <div className="row" >
                    {/* <div className="backgroundimg"> */}
                        {/* <img src="assets/rc_images/landscape2.png" className="d-block card-img" alt="" /> */}
                        <img
                            src={images[currentImageIndex]}
                            alt="slideshow"
                            style={{ width: "100%" }}
                        />
                    {/* </div> */}

                        {/* <div className="topleft_text">
                             <span class="badge badge-danger">ทดลองใช้โปรแกรม 15 วัน ฟรี</span><br/>
                             <span class="badge badge-danger">พร้อมโอนข้อมูลเก่าให้ ฟรี</span>
                        </div> */}
                        
                        {/* ++++++++++++++++++++++++++++++++++ */}
                        {/* --- Picture ---------------------- */}
                            {/* <div className="container head-text"> */}
                                {/*  */}
                                {/* <div className="topleft_text"> */}
                                        {/* <h5> ทดลองใช้โปรแกรม 15 วัน ฟรี </h5> */}
                                        {/* <br/> */}
                                        {/* <span class="badge badge-danger">ทดลองใช้โปรแกรม 15 วัน ฟรี</span> */}
                                        {/* <span class="badge bg-success">พร้อมโอนข้อมูลเก่าให้ ฟรี</span><br/> */}
                                        {/* <span class="badge badge-danger">พร้อมโอนข้อมูลเก่าให้ ฟรี</span><br/> */}
                                        {/* <span class="badge badge-info">Info, warning</span> */}
                                {/* </div>       */}
                            {/* </div> */}
                        {/* ---------------------------------- */}
                        {/* ++++++++++++++++++++++++++++++++++ */}

                </div>                                
           </div> 

           {/* ---------------------------------- LOG IN -------------------------------------- */}
           <div className="container-fluid mt-0" align="center">
                      <div className="row">
                            
                      </div>
                  </div>
                  <p />
            {/* -------------------------------------------------------------------------------- */}
            
        </>
      )

}

export default Header;
